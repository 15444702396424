@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  background-color: white;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family:  Calibri,'Gill Sans', 'Gill Sans MT', 'Trebuchet MS', sans-serif;
  color: #6b94ae;
  font-weight: 500;
}



.swiper {
  width: 60%;
  height: 100%;
  position: relative;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.swiper-slide img {
  height: 300px;
  width: 500px;

  margin: 0 auto;
}

@media (max-width: 520px) {
  .swiper-slide img {
    width: 340px;
    height: 230px;
  }

  .swiper-slide {
    width: 100%;
  }

  .swiper {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100% !important;
  }
}
.swiper-pagination-bullet {
  background-color: white !important;
}


.swiper-button-next,.swiper-button-prev{
    color: #fff !important;
      font-weight: bold;
      scale: .6;
    }


  @media (max-width:394px) {
      .swiper-button-next,
      .swiper-button-prev {
        color: #6b94ae !important;

      }
    }


@media (min-width: 768px) {
  .swiper-button-next,
  .swiper-button-prev {
    color: white !important;
  }
}


@media (max-width: 768px) {
  .swiper {
    width: 80%;
    height: 80%;
  
  }
}


